<template>
  <div class="index1">
    <div class="map">
      <div id="container" style="width:100%;height:100%;">
      <div class="i_message" ref="treePlate">
         <div>编号：{{forms.treeId}}</div>
         <div>经度：{{forms.longitude}}</div>
         <div>纬度：{{forms.latitude}}</div>
         <!-- <div>地址：{{forms.address}}</div> -->
         <div>所属项目：{{forms.projectName}}</div>
         <div>创建时间：{{forms.createTime}}</div>
         <div>作业人：{{forms.groupName}}</div>
    </div>
      </div>
  </div>
  <div class="s_subject">
   <div class="top_left">
    <!-- 定位/项目 -->
    <div class="s_flex">
      <div class="s_imgs"><img src="../../assets/u133.png" alt=""></div>
      <!-- <div>当前位置：镇江</div> -->
      <div class="s_title">
        请选择项目：
        <a-select allowClear style="width: 120px;margin-right: 20px;" v-model="projectName" @change="handleChange">
          <a-select-option :value="item.projectId" v-for="(item,index) in projectList" :key="index">
            {{item.projectName}}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <!-- 日期 -->
    <div class="s_time">
      {{this.Weather.date}} | {{this.Weather.week}}
    </div>
    <!-- 天气情况 -->
    <div class="s_weather">
      <div style="margin-right:4rem">
        <div class="s_limit">{{this.Weather.temp}}℃</div>
        <div class="s_weathers">天气：{{this.Weather.weather}}</div>
      </div>
      <div class="s_winds">
        <div class="s_humidity">湿度：{{this.Weather.humidity}}</div>
        <div class="s_wind">空气质量：{{this.Weather.affect}}</div>
      </div>
    </div>
   </div>
   <!-- 枯死木统计 -->
   <div class="top_left s_statistics">
     <!-- 总数统计 -->
     <div>
       <div class="s_ustatistic">
         <div class="s_vertical"></div>
         <div class="s_text">枯死木统计</div>
        </div>
       <div class="s_sum">疫木总数（棵）</div>
       <div class="s_quantity">{{quantity}}</div>
     </div>
     <div class="s_line"><img src="../../assets/u152.png" alt=""></div>
     <!-- 月/周统计 -->
     <div class="s_line">
       <!-- 本月新增 -->
       <div style="margin:0rem 1rem">
         <div class="s_add">本月新增</div>
         <div class="s_quantitys">{{monthNum}}</div>
       </div>
       <div class="s_line"><img src="../../assets/u152.png" alt=""></div>
       <!-- 本周新增 -->
       <!-- <div>
         <div class="s_add">本周新增</div>
         <div class="s_quantitys">23</div>
       </div> -->
     </div>
     <!-- 图表 -->
     <div>
       <div id="main" style="width:15rem;height:8rem;"></div>
     </div>
   </div>

  </div>
  </div>
</template>

<script>
import gif from '../../assets/tree_red.png'
import { registerLayout } from 'echarts'
import * as echarts from 'echarts';
import { location } from "../../utils/Location.js";
import {wgs84_to_gcj02} from '../../utils/wgs84_to_gcj02.js'

export default {
  name: "State",
  data() {
    return {
      projectList: [],
      Weather: {},
      markers: [],
      map: null,
      zoom: 6,
      center: [117.12277857421876, 36.64398657215667],
      forms: {
        treeId: '',
        longitude: '',
        latitude: '',
        address: '',
        projectName: '',
        createTime: '',
        groupName: ''
      },
      quantity: 0, //总数
      monthNum: 0, //本月总数
      projectName: sessionStorage.getItem('projectName'),
      projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
    }
  },
  components: {

  },
  mounted() {
    // this.handleList()
    this.onLoad()
    this.getWeather()
    this.handleProject()
    this.projectChange()
  },
  created() {
    this.getLocation()
  },
  methods: {
    // 定位--不生效
    getLocation() {
      let geolocation = location.initMap("map-container"); //定位
      AMap.event.addListener(geolocation, "complete", result => {
        console.log(result)
      });
 },
    // 选择的项目
    handleChange(e) {
      this.projectIdList = e
      this.handleProject()
      this.onLoad()
    },
    // 项目列表
    projectChange() {
      this.$axios.get(this.api + `/project/list?projectIdList=${this.projectIdList}`).then(res => {
        this.projectList = res.data.list
      })
    },
    // 树标
    handleProject() {
      this.$axios.get(this.api + `/investigation/list?projectIdList=${this.projectIdList}`).then(res => {
        this.pieClick() //饼图统计
        let array = []
        res.data.list.forEach(res => {
          // JSON.parse(res.area_array).forEach(res1 => {
            array.push(res)
          // })
        })
        this.setMaker(array)
      })
    },
    // 天气
    getWeather() {
      this.$axios.get(this.api + "/weather/info", {params: {city: '山东省'}}).then(res => {
        const weather = res.data.result
        this.Weather = {
          date: weather.date,
          week: weather.week,
          weather: weather.weather,
          temp: weather.temp,
          humidity: weather.humidity,
          affect: weather.aqi.aqiinfo.affect
        }
      })
    },
    // 地图
    setMaker(List) {
      this.markers = []
      List.forEach(item => {
        const icon = new AMap.Icon({
          image: gif,
          size: new AMap.Size(32, 32),//设置icon的大小
          imageSize: new AMap.Size(32, 32)
        })
        let adds = wgs84_to_gcj02(item.longitude-0, item.latitude-0)
			  let newlongitude = JSON.stringify(adds).split(',')[0].replace("[","")-0
			  let newlatitude = JSON.stringify(adds).split(',')[1].replace("]","")-0
        var marker = new AMap.Marker({
          icon: icon,
          offset: new AMap.Pixel(-26, -26),
          position: new AMap.LngLat(newlongitude.toFixed(6), newlatitude.toFixed(6)),
          item: item
          //       label: {
          //           offset: new AMap.Pixel(-10, -40),  //设置文本标注偏移量
          //           content: `<div class="mymarkerLabel"><span style="margin-right:10px;">${item.label}</span><img src="https://webapi.amap.com/images/close2.gif" alt=""><img src="static/img/sharp.png" style="width: 20px;height: 20px;margin: 0 auto;position: absolute;bottom: -20px;left: 0;"/></div>`, //设置文本标注内容
          //           direction: 'right' //设置文本标注方位
          //       },
          //       title: item.label,
        });
        marker.on('click', this.getMarkerInfo)//设置标记点触发点击事件
        this.markers.push(marker)
      });
      this.map.add(this.markers);
    },
    getMarkerInfo(e) {
      this.center[0] = e.lnglat.lng
      this.center[1] = e.lnglat.lat
      if (this.zoom < 13) {
        this.zoom = this.zoom + 1
        this.map.setCenter(this.center) //设置中心点
        this.map.setZoom(this.zoom) //修改缩放比例
      } else {
        this.zoom = 13
        this.map.setCenter(this.center) //设置中心点
        this.map.setZoom(this.zoom) //修改缩放比例
      }
      let item = e.target.w.item
       this.forms = {
        treeId: item.treeId,
        longitude: item.longitude,
        latitude: item.latitude,
        address: item.address,
        projectName: item.projectName,
        createTime: item.createTime,
        groupName: item.groupName,
      }
      var content = this.$refs.treePlate
      var infoWindow = new AMap.InfoWindow({
        anchor: 'bottom-center',
        content: content,
      })
      infoWindow.open(this.map, [e.lnglat.lng, e.lnglat.lat])
    },
    onLoad() {
      this.map = new AMap.Map('container', {
        center: this.center,
        // resizeEnable: true,//是否监控地图尺寸变化
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer()
        ],
        zoom: this.zoom
      });
      //     AMap.event.addListener(this.map, "zoomend",()=>{            
      //           var zoom = this.map.getZoom();//获取缩放级le.log(ole.log())
      //          this.zoom=zoom
      //         });
    },
    // 饼图
   async pieClick() {
      const res = await this.$axios.get(this.api + `/investigation/statistics?projectIdList=${this.projectIdList}`)
      this.quantity = res.data.total
      this.monthNum = res.data.monthNum
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option = {
          // stillShowZeroSum: true,
          //鼠标划过时饼状图上显示的数据
          tooltip: {
            trigger: 'item',
            // formatter: '{b}: {d}%',
            textStyle: {
              color: '#575757',
              fontSize: 8
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'null',
            // borderWidth: 1,
            borderRadius: 5,
            // formatter: '<span style="display:inline-block;position:relative; top:-3px;margin-right:2px;border-radius:5px;width:5px;height:5px;background-color:#3699F1"></span>{b}: {d}%',
            formatter:function(param){
               return param.marker +"&nbsp;"+param.name+"："+ param.percent +'%' + "<br>";
            }
          },
          //图例
          legend: {//图例  标注各种颜色代表的模块
            icon: "circle",
            bottom: 5,//控制图例出现的距离  默认左上角
            left: 'center',//控制图例的位置
            itemWidth: 7,//图例颜色块的宽度和高度
            itemHeight: 7,
            textStyle: {//图例中文字的样式
              color: '#ffffff',
              fontSize: 9
            },
            itemGap: 18,//间距
            data: ['sum', 'month']//图例上显示的饼图各模块上的名字
          },
          color: ['#1883e6', '#14adb2'],//饼图中各模块的颜色
          // 饼图数据
          series: {
            type: 'pie',             //echarts图的类型   pie代表饼图
            radius: '55%',           //饼图中饼状部分的大小所占整个父元素的百分比
            center: ['50%', '40%'],  //整个饼图在整个父元素中的位置
            labelLine: {    //图形外文字线
                length: 20,
                length2: 15
              },
            data: [                  //每个模块的名字和值
              { 
              name: 'sum', value: res.data.total, 
              label: {
                   //单独显示该数据项
                   show: true,
                   fontSize: 8,
                   color: '#ffffff'
              },
              itemStyle: {
                color: "rgba(50,123,250,0.7)",
                borderColor: "rgba(50,123,250,1)",
                borderWidth: 1
              }
            },
              { 
              name: 'month', value: res.data.monthNum,
              label: {
                   //单独显示该数据项
                   show: true,
                   fontSize: 8,
                   color: '#ffffff'
              },
              itemStyle: {
                    color: "rgba(23,216,161,0.7)",
                    borderColor: "rgba(23,216,161,1)",
                    borderWidth: 1
                }
              },
            ],
            itemStyle: {
                label: {
                  show: true,//饼图上是否出现标注文字 标注各模块代表什么  默认是true
                },
                labelLine: {
                  show: true//官网demo里外部标注上的小细线的显示隐藏    默认显示
                }
            },
          }

        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option)
    }
  },
}
</script>

<style scoped>
div /deep/ .ant-select-selection {
  color: #fff;
  background-color: rgba(37, 63, 91, 1.0);
}

div /deep/ .ant-select-arrow {
  color: rgb(249 240 240 / 91%);
}

.index1 {
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
}

.map {
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  top: 20px;
  left: 20px;
}
/* --------- */
/* 主体 */
.s_subject {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #ffffff;
  width: 30%;
}
.top_left {
  background: url(../../assets/u32.png) no-repeat;
  background-size: 100% 100%;
  padding: 1rem 2rem;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-bottom: 1rem;
}
.s_flex {
  display: flex;
  align-items: center;
}
/* 定位图 */
.s_imgs img {
  width: 27px;
  margin-right: 0.5rem;
}
/* 请选择项目 */
.s_title {
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: 1.5rem;
}
.s_title >>> .ant-select-selection--single {
  height: 28px;
}
/* 日期 */
.s_time {
  width: 100%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  padding-left: 2.1rem;
  margin-top: 1rem;
}
/* 天气 */
.s_weather {
  display: flex;
  align-items: center;
  padding-left: 2.1rem;
  margin: 1rem 0 2rem 0;
}
/* 度 */
.s_limit {
  font-size: 36px;
}
/* 天气 */
.s_weathers {
  font-size: 15px;
}
/* 湿度/风力 */
.s_winds {
  text-align: left;
}
.s_humidity {
  font-size: 18px;
}
.s_wind {
  margin-top: 1.1rem;
}
/* 枯死木统计 */
.s_statistics {
  display: flex;
  padding: 1rem 1.2rem 1.5rem 1.2rem;
}
.s_ustatistic {
  display: flex;
  align-items: center;
}
.s_vertical {
  width: 2.3px;
  height: 17px;
  background-color: #0099ff;
  margin-right: 9px;
}
.s_text {
  font-size: 16px;
}
.s_sum {
  font-size: 14px;
  margin: 0.8rem 0.65rem 0.2rem 0.7rem;
}
.s_quantity {
  font-size: 24px;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
}
/* 列 */
.s_line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
/* 新增 */
.s_add {
  height: 1rem;
  margin-bottom: 1rem;
}
.s_quantitys {
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
}

/* 信息框 */
.i_message div {
  color: #000000;
  margin: 10px 8px 0 10px;
}
</style>
